@import "../../styles/variables.less";

.listing {
    margin: auto;
    max-width: 80%;
    padding: 50px 60px 0;
    display: flex;
    @media only screen and (max-width: 800px) {
        padding: 20px 0;
        margin: 20px auto;
        flex-wrap: wrap;
    }
    &__title-border {
        height: 4px;
        width: 8vw;
        margin-top: -1em;
        margin-bottom: 1em;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, @primary-color 0%, @secondary-color 100%);
    }

    > div > h1 {
        font-size: 2em;
        font-weight: normal;
        letter-spacing: .01em;
    }
    > div > div {
        font-size: 1.2em;
    }
    
    > div > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

@primary-color: #FFCB05;@font-size-base: 16px;@border-radius-base: 16px;