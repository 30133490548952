@import "../../../styles/variables.less";

.contact-form {
    width: 100%;
    max-width: calc(@site-max-width + 120px);
    margin: 50px auto;
    padding: 0 60px;
    display: flex;
    @media only screen and (max-width: 800px) {
        padding: 0 20px;
        margin: 20px auto;
        flex-wrap: wrap;
    }

    > div {
        > h1 {
            font-family: 'Roboto Mono';
            color: @primary-color;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: .01em;
        }
        
        > div {
            font-size: 16px;
            > ul {
                margin-left: 40px !important;
            }
            margin: 50px auto;
        }
        .ant-form {
            .ant-col-hide {
                display: none;
            }

            .ant-btn-primary {
                color: #000;
            }
            
        }
    }
}

@primary-color: #FFCB05;@font-size-base: 16px;@border-radius-base: 16px;