@import "../../styles/variables.less";

.banner {
  position: relative;

    &__wrapper{
      width: 100%;
      max-width: @site-max-width;
      margin-top: -32px;

      @media only screen and (max-width: @header-mobile-width) {
        margin-top: 0
      }
    }

    &__sub-banner {
        display: none;
        position: absolute;
        bottom: 32px;
        left: 0;
        background-color: @banner-sub-banner-bg;
        padding: 15px 0;
        width: 100% !important;
        text-align: center;
        font-weight: bold;
        font-size: 1.3em;
        letter-spacing: .025em;
        box-shadow: @banner-sub-banner-box-shadow;
        color: @banner-sub-banner-color;
        @media only screen and (max-width: @header-mobile-width) {
            font-size: 1.2em;
            bottom: 0;
        }
    }

    &__default-sub-banner {
        padding-top: calc(1.8em + 30px) !important;
        padding-bottom: calc(1.8em + 30px) !important;
        .banner__sub-banner {
            display: block;
        }
        > div > div > div {
            p {
                margin-bottom: 0;
                &:nth-last-child(1) {
                    margin-bottom: 1em;
                }
            }
        }
        @media only screen and (max-width: @header-mobile-width) {
            > div > div > div {
                margin: auto;
            }
        }
    }
    
    &__default {
        /*background-attachment: fixed;*/
    }

    &__default, &__default-sub-banner {
        min-height: 600px;
        height: calc(100vh - @header-size);
        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100vh - @header-size-mobile);
        }
        .banner__darken-bg {
            display: none;
        }
    }
    &__secondary {
        position: relative;
        height: 200px;
        text-align: left;
        @media only screen and (max-width: @header-mobile-width) {
            height: calc(30vh - @header-size-mobile);
        }
        div:nth-child(1) {
            z-index: 2;
            margin-top: 0;
            h2 {
                text-shadow: 0 0 10px @banner-secondary-text-shadow;
            }
        }
        .banner__darken-bg {
            width: 100%;
            height: 100%;
            background-color: @banner-secondary-darken-bg-color;
            position: absolute;
            z-index: 1;
        }
        @media only screen and (max-width: @header-mobile-width) {
            height: calc(33vh - @header-size-mobile) !important;
        }
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 0 50px;
        width: 100%;
        background-color: #777;
        background-size: cover;
        background-repeat: no-repeat;

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100vh - @header-size-mobile);
            min-height: unset;
            text-align: center;
            padding: 0;
            &.banner__default {
                min-height: 500px;
            }
            &.banner__secondary {
                min-height: 150px;
            }
        }
        @media only screen and (min-width: @header-mobile-width) {
            &.banner__default {
                min-height: 700px;
            }
            &.banner__secondary {
                min-height: 400px;
            }
        }
        > div {
            width: 100%;
            > div {
                > div {
                    width: calc(80% - 40px);
                    height: auto;
                    text-shadow: 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000,
                        0 0 20px #000000, 0 0 20px #000000, 0 0 20px #000000, 0 0 20px #000000, 0 0 20px #000000,
                        0 0 40px #000000, 0 0 40px #000000, 0 0 40px #000000, 0 0 40px #000000, 0 0 40px #000000;
                    > h1 {
                        font-family: 'Roboto Mono';
                        margin-bottom: 0;
                        font-weight: 400;
                        font-size: 2em;
                        color: #FFCB05;
                        letter-spacing: .01em;
                        @media only screen and (max-width: @header-mobile-width) {
                            font-size: 2em;
                            line-height: 1.2em;
                            margin-bottom: 10px;
                        }
                    }
                    @media only screen and (max-width: @header-mobile-width) {
                        width: 100%;
                        padding: 0 20px
                    }
                    > div {
                        > p {
                            font-size: 1.2em;
                            color: #fff;
                            @media only screen and (max-width: @header-mobile-width) {
                                font-size: 1.2em;
                                line-height: 1.2em;
                            }
                        }
                    }
                }        
            }
            .actions {
                position: relative;
                display: inline-block;
                padding-left: 0;
                margin-top: 20px;
                text-align: center;
                .actions__item {
                    transition: width 0.5s;
                    text-shadow: 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000;
                    h4 {
                        font-family: 'Roboto Mono';
                        text-transform: uppercase;
                        font-size: 14px;
                        margin-bottom: 0.1em;
                    }
                    p {
                        font-size: 1em;
                        margin-bottom: 0.1em;
                    }
                    a {
                        font-size: 1em;
                        text-decoration: none;
                        font-family: 'Roboto Mono';
                        &:hover {
                            color: #ffcb05;
                        }
                    }
                    background: rgba(15, 0, 50, 0.5 );
                    &:hover {
                        text-shadow: 0 0 5px #000000, 0 0 5px #ffcb05, 0 0 10px #ffffff;
                        h4 {
                            color: #ffffff;
                        }
                        background: rgba(75, 0, 200, 0.5 );
                    }
                }
            }
        }
    }
}
    
@primary-color: #FFCB05;@font-size-base: 16px;@border-radius-base: 16px;